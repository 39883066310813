const qs = require("querystring");

const makeAuthUrl = (url, umi_id, key) => {
  const q = qs.stringify({
    umi_id: umi_id,
    key: key,
  });
  return url + "?" + q;
};

export default makeAuthUrl;
