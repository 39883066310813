<template>
  <div>
    <h2>Сверки</h2>
    <ul class="">
      <li v-for="(listItem, index) in list" :key="index">
        <router-link
          :to="{ name: 'AuditListItem', params: { id: listItem.Id } }"
        >
          {{ listItem.DateStart }} - {{ listItem.DateEnd }}
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
import api from "../../../services/api";
import makeAuthUrl from "../../../services/qs";

export default {
  data() {
    return {
      list: [],
    };
  },
  async mounted() {
    await this.fetchList();
  },
  methods: {
    async fetchList() {
      const url = makeAuthUrl("/audit/customer-audit-list-list", this.$route.query.umi_id, this.$route.query.key);
      const { data } = await api.get(url);
      this.list = data;
    },
  },
};
</script>
