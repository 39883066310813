import axios from "axios";
const instance = axios.create({
  baseURL: process.env.VUE_APP_API_HOST,
  // transformRequest: [
  //   function (data, headers) {
  //     // headers.Authorization = token.getAuthorizationHeader();
  //     headers["Content-Type"] = "application/json;charset=utf-8";
  //     return JSON.stringify(data);
  //   },
  // ],
  // validateStatus(status) {
  //   return status < 500;
  // },
});
//
// instance.interceptors.response.use((response) => {
//   // if (response.headers.authorization && response.headers.authorization !== response.config.headers.Authorization) {
//   //   console.log('api new token ', response.headers.authorization);
//   //   eventhub.$emit('API_AUTH_TOKEN_ARRIVE', response.headers.authorization);
//   // }
//   // if (response.status > 200) {
//   //   eventhub.$emit('api.error', response);
//   // }
//   // if (response.status === 401) {
//   //   token.unset();
//   //   eventhub.$emit('api.logout', response);
//   // }
//   return response;
// }, error => Promise.reject(error));

export default instance;
